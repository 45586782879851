import { Payload } from './types'
import { ExternalPayloads } from './external-payloads'
import { NativePayloads } from './native-payloads'
import { AnalyticsPayloads } from './analytics-payloads'
import { ToastPayloads, ToastReactionPayloads, ToastStatePayloads } from './toast-payloads'
import { CashierPayloads } from './cashier-payloads'
import { NavigationPayloads } from './navigation-payloads'
import { GameLaunchPayloads } from './launch-payloads'
import { SearchPayloads } from './search-payloads'
import { SupportPayloads } from './support-payloads'
import { LoggerPayloads } from './logger-payloads'
import { ScrollPayloads } from './scroll-payloads'
import { PopupPayloads, PopupReactionPayloads } from './popup-payloads'
import { GamingPayloads } from './gaming-payloads'

export interface MetaData {
  source: Source
}

interface PlayerPayload<K> extends Payload<K> {
  id: string
}

export type FabricTopicToPayload = {
  analytics: AnalyticsPayloads
  player: PlayerPayload<'logged-in'> | PlayerPayload<'logged-out'>
  spine: Payload<'loaded'> & { isLoggedIn: boolean }
  external: ExternalPayloads
  native: NativePayloads
  toast: ToastPayloads
  'toast:reaction': ToastReactionPayloads
  'toast:state': ToastStatePayloads
  cashier: CashierPayloads
  navigation: NavigationPayloads
  launch: GameLaunchPayloads
  search: SearchPayloads
  support: SupportPayloads
  logger: LoggerPayloads
  scroll: ScrollPayloads
  popup: PopupPayloads
  'popup:reaction': PopupReactionPayloads
  gaming: GamingPayloads
}

export type Source = string

export interface IMessageBus<TopicToPayload> {
  publish<Topic extends keyof TopicToPayload>(
    topic: Topic,
    payload: TopicToPayload[Topic],
    metaData?: MetaData
  ): void
  subscribe<Topic extends keyof TopicToPayload>(
    topic: Topic,
    handler: (payload: TopicToPayload[Topic], metaData: MetaData) => void
  ): {
    unsubscribe(): void
  }
}
