import { SkeletonList } from 'Components/GameList/SkeletonList'
import { TApplicationParams } from './Application'

export const AppLoading = (props: TApplicationParams) => {
  if (props.component === 'list') {
    return <SkeletonList {...props} />
  } else if (props.component === 'launcher') {
    return (
      <div className="absolute left-0 top-0 right-0 bottom-0 size-full overflow-hidden rounded-md skeleton"></div>
    )
  }

  return null
}
