import cx from 'classnames'
import { ReactNode } from 'react'
import { TGameListBackgroundColor } from '../types'
import { convertCmsUrlToImgixUrl } from 'Components/Images/utils'

type TGameListBackground = {
  backgroundColor?: TGameListBackgroundColor
  backgroundPattern?: string
  className?: string
  children: ReactNode
}

export const GameListBackground = ({
  backgroundColor,
  backgroundPattern,
  className,
  children
}: TGameListBackground) => {
  return (
    <div
      className={cx(
        backgroundColor === 'purple-80' && 'bg-purple-80',
        backgroundColor === 'grey-0' && 'bg-grey-0',
        backgroundColor === 'white' && 'bg-white',
        backgroundColor === 'purple-gradient' &&
          'bg-gradient-to-b from-purple-80 to-purple-60 from-[36.03%] to-[65.77%]',
        backgroundPattern && 'bg-top'
      )}
    >
      <div
        className={className}
        style={{
          backgroundImage:
            backgroundPattern?.startsWith('http') || backgroundPattern?.startsWith('data:')
              ? `url(${convertCmsUrlToImgixUrl(backgroundPattern)})`
              : undefined
        }}
      >
        {children}
      </div>
    </div>
  )
}
