import cx from 'classnames'

const CLASS_NAMES_MAPPING: { [key: `${TButton['content']}_${TButton['variant']}`]: string } = {
  white_primary: 'bg-purple-60 text-white',
  white_secondary: 'bg-grey-0 text-purple-60',
  white_tertiary: 'text-purple-60',
  'grey-0_primary': 'bg-purple-60 text-white',
  'grey-0_secondary': 'bg-white text-purple-60',
  'grey-0_tertiary': 'text-purple-60',
  'purple-80_primary': 'bg-teal-50 text-purple-80',
  'purple-80_secondary': 'bg-purple-60 text-white',
  'purple-80_tertiary': 'text-white',
  'purple-60_primary': 'bg-teal-50 text-purple-80',
  'purple-60_secondary': 'bg-purple-60 text-white',
  'purple-60_tertiary': 'text-white'
}

type TButton = {
  variant: 'primary' | 'secondary' | 'tertiary'
  context: 'white' | 'grey-0' | 'purple-80' | 'purple-60'
  small?: boolean
  className?: string
  children: React.ReactNode
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const Button = ({
  variant = 'primary',
  context = 'white',
  small = false,
  className,
  children,
  ...rest
}: TButton) => {
  return (
    <button
      type="button"
      className={cx(
        'click font-bold rounded-full border-b-purple-100/30',
        CLASS_NAMES_MAPPING[`${context}_${variant}`],
        small
          ? 'text-btn-sm px-xs pt-[7px] pb-[6px] border-b-[6px]'
          : 'text-btn-rg h-[52px] px-lg py-[11px] border-b-[6px]',
        className
      )}
      {...rest}
    >
      {children}
    </button>
  )
}
